<template>
  <div class="d-flex flex-column max-height-100">
    <div class="d-flex flex-grow-1 fill-height" :style="{ height: `calc(100vh - ${headerHeight}px)` }">
    <iframe
      ref="nodeRedIframe"
      :src="nodeRedUrl"
      width="100%"
      height="100%"
      style="border: none;"
      @load="sendMessageToIframe"
    ></iframe>
    </div>
  </div>
</template>

<script>
import constants from '@/utils/constants';

export default {
  name: "NodeRedEmbed",
  computed: {
    headerHeight() {
      return this.$store.state.global.appHeaderMapHeightInPx;
    },
    nodeRedUrl() {
      return `${constants.baseURL_AUTOMATION_TOOL}:1895`;
    }
  },
  mounted() {
		const puiContainerHeader = document.getElementsByClassName('pui-containerHeader')[0];
		puiContainerHeader.style.display = 'none';
	},
	beforeDestroy() {
		console.log('info: beforedestroy dashboardpanel');

		if (document.getElementsByClassName('pui-containerHeader')[0]) {
			document.getElementsByClassName('pui-containerHeader')[0].style.display = '';
		}
	},
  methods: {
    sendMessageToIframe() {
      const iframe = this.$refs.nodeRedIframe;
      const iframeWindow = iframe.contentWindow;

      // Enviar mensaje al iframe para cambiar el texto del header
      setTimeout(() => {
        iframeWindow.postMessage(
          {
            type: 'changeHeaderText',
            text: this.$t('automationTool.title')
          },
          `${constants.baseURL_AUTOMATION_TOOL}:1895`
        );
        //
        
      }, 3000); // Esperar 3 segundos
    } //end sendMessageToIframe
  }
};
</script>

<style scoped>
div {
  height: 100vh;
}
</style>